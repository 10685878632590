//import {Interval} from 'date-fns'
//import {parseISO, isValid, format} from 'date-fns/esm/fp'

import { Interval, parseISO, isValid, format } from 'date-fns'

const formatDate = (d) => format(d, "yyyy-MM-dd'T'HH:mm:ss")
/**
 * Parses input string into Interval date-fns object
 *
 * supports only <start>/<end> notation
 *
 * @param intervalString ISO-8601 interval string
 */
export const parseInterval = (intervalString: string): Interval => {
	const parts = intervalString.toString().split('/')
	if (parts.length !== 2) {
		throw new Error('Invalid interval: ' + intervalString)
	}
	const dateParts = parts.map((i) => parseISO(i))
	if (!dateParts.every((i) => isValid(i))) {
		throw new Error('Invalid interval: ' + intervalString)
	}

	return {
		start: dateParts[0],
		end: dateParts[1],
	}
}

export const serializeInterval = (interval: Interval): string =>
	formatDate(interval.start) + '/' + formatDate(interval.end)
