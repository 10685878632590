import { Query } from '@/types'

export default class QueryStorage {
	constructor(private readonly endpoint, private readonly login) {
		if (typeof endpoint !== 'string') {
			throw new Error('Endpoint must be string, got: ' + typeof endpoint)
		}
		if (typeof login !== 'string') {
			throw new Error('Login must be string, got: ' + typeof login)
		}
	}

	private enhanceKey(key: string) {
		return `${this.login}_${this.endpoint}_${key}`
	}

	get(key: string): Query {
		const raw = localStorage.getItem(`agmir_${this.enhanceKey(key)}`)
		if (!raw) {
			return null
		}
		return JSON.parse(raw)
	}

	set(key: string, value: Query<any>) {
		const raw = JSON.stringify(value)
		localStorage.setItem(`agmir_${this.enhanceKey(key)}`, raw)
	}
}
