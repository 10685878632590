import Vue from 'vue'
import Vuex from 'vuex'

import afterImport from './modules/after-import'
import auth from './modules/auth'
import cacheWatcher from './modules/cache-watcher'
import classes from './modules/classes'
import config from './modules/config'
import constants from './modules/constants'
import endpoints from './modules/endpoints'
import favorite from './modules/favorite'
import individuals from './modules/individual'
import lang from './modules/lang'
import license from './modules/license'
import literals from './modules/literals'
import notifications from './modules/notifications'
import ontologySettings from './modules/ontology-settings'
import ontologyVersions from './modules/ontology-versions'
import pluginModules from './modules/plugin-modules'
import proposed from './modules/proposed'
import prefixes from './modules/prefixes'
import references from './modules/references'

Vue.use(Vuex)

class State {} //NOSONAR

export default new Vuex.Store({
	state: new State(),
	modules: {
		afterImport,
		auth,
		cacheWatcher,
		classes,
		config,
		constants,
		endpoints,
		favorite,
		individuals,
		lang,
		license,
		literals,
		notifications,
		pluginModules,
		proposed,
		prefixes,
		ontologySettings,
		ontologyVersions,
		references
	},
})
