import {apiClient} from '@/api/client'
import {
  BaseSortItem,
  FilterGroup,
  SortDirection,
  Api,
  NamedIndividualStaticProps,
  SetResult,
  DynEntity,
  DropResult,
  Schema,
  RDF,
  ReferenceValue,
  NamedIndividual,
  NamedIndividualDraft,
  NamedIndividualPatch,
  PureEntity,
  Operation,
  AcceptRouteState,
} from '~/shared'
import {Query} from '@/core/group-edit'

export type RequestFilteringParams = {
  filter?: FilterGroup[]
  operation?: Operation
}

export type GetIndividualsRequestParams = RequestFilteringParams & {
  limit?: number
  offset?: number
  withoutSubClasses?: boolean
  sort?: BaseSortItem<any, SortDirection>[]
  columns?: { key: string; uom?: { id: string } }[]
}

export type GetIndividualsResponse = {
  totalCount: number
  items: NamedIndividual[]
}

const newEncodeURIComponent = (str) =>
  encodeURIComponent(str)
    .replace(/[!'()*.]/g, (e) => '%' + e.charCodeAt(0)
                                        .toString(16)) //NOSONAR

export const getList = async (
  endpoint: string,
  classId: string,
  params: GetIndividualsRequestParams,
  language?: string,
  abortController?: AbortController,
  version?: string
): Promise<GetIndividualsResponse> => {
  if (!classId) {
    return {
      totalCount: 0,
      items: [],
    }
  }
  const queryStringItems: string[] = []
  if (typeof params.operation === 'string') {
    queryStringItems.push('operation=' + params.operation)
  }
  if ('limit' in params) {
    queryStringItems.push('limit=' + params.limit)
  }
  if ('offset' in params) {
    queryStringItems.push('offset=' + params.offset)
  }
  if ('withoutSubClasses' in params && params.withoutSubClasses) {
    queryStringItems.push('withoutSubClasses')
  }
  if ('columns' in params) {
    queryStringItems.push(
      'columns=' +
      encodeURIComponent(
        params.columns
              .map((e) => {
                let v = e.key
                if (e.uom) {
                  v += '|' + e.uom.id
                }
                return v
              })
              .join(',')
      )
    )
  }
  if (typeof params.sort !== 'undefined') {
    params.sort
          .filter((i) => typeof i === 'object' && i !== null)
          .filter(({key}) => key !== 'id')
          .forEach((sortItem) => {
            queryStringItems.push(
              'sort=' + (sortItem.direction === 'DESC' ? '-' : '') + newEncodeURIComponent(sortItem.key)
            )
          })
  }
  if (params.filter) {
    queryStringItems.push('filter=' + newEncodeURIComponent(JSON.stringify(params.filter)))
  }
  if (language) {
    queryStringItems.push('language=' + language)
  }
  let url = `/endpoints/${endpoint}`
  if (version) {
    url = `${url}/version/${encodeURIComponent(version)}`
  }
  url = `${url}/classes/${newEncodeURIComponent(classId)}/individuals`
  if (queryStringItems.length) {
    url = `${url}?${queryStringItems.join('&')}`
  }
  const response = await apiClient.get(url, {signal: abortController?.signal})
  return response.data
}

export const create = async (
  endpoint: string,
  data: NamedIndividualDraft,
  language?: string
): Promise<Api.Response<NamedIndividual> | 202> => {
  let url = `/endpoints/${endpoint}/individuals`
  const params = []
  if (language) {
    params.push('language=' + language)
  }
  if (params.length) {
    url += '?' + params.join('&')
  }
  const response = await apiClient.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (response.status !== 202) {
    return response.data
  }
  else {
    return 202
  }
}

export const patch = async (
  endpoint: string,
  patch: NamedIndividualPatch,
  language?: string
): Promise<Api.Response<NamedIndividual> | 202> => {
  const query = []
  let url = `/endpoints/${endpoint}/individuals/${newEncodeURIComponent(patch.id)}`
  if (language) {
    query.push(`language=${language}`)
  }
  if (query.length) {
    url = `${url}?${query.join('&')}`
  }
  const response = await apiClient.patch(url, patch, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (response.status !== 202) {
    return response.data
  }
  else {
    return 202
  }
}

export const remove = async (endpoint: string, id: string): Promise<void | true | 202> => {
  const url = `/endpoints/${endpoint}/individuals/${newEncodeURIComponent(id)}`
  const response = await apiClient.delete(url)
  if (response.status !== 202) {
    return true
  }
  else {
    return 202
  }
}

export type GetIndividualsCollectionRequestParams = {
  limit?: number
  offset?: number
  id?: string
  withoutSubClasses?: boolean
  sort?: BaseSortItem<any, SortDirection>[]
  filter?: FilterGroup[]
  types: string[]
}

export const getCollection = async (
  endpoint: string,
  params: GetIndividualsCollectionRequestParams,
  language?: string,
  abortController?: AbortController
): Promise<GetIndividualsResponse> => {
  const queryStringItems: string[] = params.types.map(
    (type) => 'type=' + newEncodeURIComponent(type)
  )
  if (params.id || params.id === '') {
    queryStringItems.push('id=' + newEncodeURIComponent(params.id))
  }
  if ('limit' in params) {
    queryStringItems.push('limit=' + params.limit)
  }
  if ('offset' in params) {
    queryStringItems.push('offset=' + params.offset)
  }
  if ('withoutSubClasses' in params && params.withoutSubClasses) {
    queryStringItems.push('withoutSubClasses')
  }
  if (typeof params.sort !== 'undefined') {
    params.sort.forEach((sortItem) => {
      queryStringItems.push(
        'sort=' + (sortItem.direction === 'DESC' ? '-' : '') + newEncodeURIComponent(sortItem.key)
      )
    })
  }
  if (typeof params.filter !== 'undefined') {
    queryStringItems.push('filter=' + newEncodeURIComponent(JSON.stringify(params.filter)))
  }
  if (language) {
    queryStringItems.push('language=' + language)
  }
  const url = `/endpoints/${endpoint}/individuals${
    queryStringItems.length ? '?' : ''
  }${queryStringItems.join('&')}`
  const response = await apiClient.get(url, {signal: abortController?.signal})
  return response.data
}

export const count = async (
  endpoint: string,
  params: GetIndividualsCollectionRequestParams,
  language?: string
): Promise<Api.Count> => {
  const queryStringItems: string[] = params.types.map(
    (type) => 'type=' + newEncodeURIComponent(type)
  )
  if (params.id || params.id === '') {
    queryStringItems.push('id=' + newEncodeURIComponent(params.id))
  }
  if ('limit' in params) {
    queryStringItems.push('limit=' + params.limit)
  }
  if ('offset' in params) {
    queryStringItems.push('offset=' + params.offset)
  }
  if ('withoutSubClasses' in params && params.withoutSubClasses) {
    queryStringItems.push('withoutSubClasses')
  }
  if (typeof params.sort !== 'undefined') {
    params.sort.forEach((sortItem) => {
      queryStringItems.push(
        'sort=' + (sortItem.direction === 'DESC' ? '-' : '') + newEncodeURIComponent(sortItem.key)
      )
    })
  }
  if (typeof params.filter !== 'undefined') {
    queryStringItems.push('filter=' + newEncodeURIComponent(JSON.stringify(params.filter)))
  }
  if (language) {
    queryStringItems.push('language=' + language)
  }
  const url = `/endpoints/${endpoint}/individuals/total${
    queryStringItems.length ? '?' : ''
  }${queryStringItems.join('&')}`
  const response = await apiClient.get(url)
  return response.data
}

export const getClassInstanceSchema = async (
  endpoint: string,
  _class: string,
  language?: string,
  abortController?: AbortController,
  version?: string
): Promise<Api.Response<Schema<any>>> => {
  const query = []
  let url = `/endpoints/${endpoint}`
  if (version) {
    url = `${url}/version/${version}`
  }
  url = `${url}/individuals/${newEncodeURIComponent(_class)}/schema`
  if (language) {
    query.push(`language=${language}`)
  }
  if (version) {
    query.push(`version=${encodeURIComponent(version)}`)
  }
  if (query.length) {
    url = `${url}?${query.join('&')}`
  }
  const response = await apiClient.get(url, {signal: abortController?.signal})
  return response.data
}

export const getSchema = async (
  endpoint: string,
  types: string[],
  language?: string,
  version?: string
): Promise<Api.Response<Schema<any>>> => {
  let url = `/endpoints/${endpoint}`
  if (version) {
    url = `${url}/version/${version}`
  }
  url = `${url}/individuals/schema?${
    types
      .map(newEncodeURIComponent)
      .map((type) => 'types=' + type)
      .join('&')
  }`
  if (language) {
    url = url + '&language=' + language.toUpperCase()
  }
  const response = await apiClient.get(url)
  return response.data
}

export const get = async (
  endpoint: string,
  id: string,
  language?: string,
  _class?: string,
  returnIfModelStorage?: boolean
): Promise<Api.Response<NamedIndividual>> => {
  let url = '/endpoints/' + endpoint + '/individuals/' + newEncodeURIComponent(id)
  const query: string[] = []
  if (language) {
    query.push('language=' + language)
  }
  if (_class) {
    query.push('class=' + encodeURIComponent(_class))
  }
  if (returnIfModelStorage) {
    query.push('returnIfModelStorage=' + encodeURIComponent(returnIfModelStorage))
  }
  if (query.length) {
    url += '?' + query.join('&')
  }
  const response = await apiClient.get(url)
  return response.data
}

export const getAcceptRoutes = async (
  endpoint: string,
  id: string
): Promise<AcceptRouteState[][]> => {
  const response = await apiClient.get(
    `endpoints/${endpoint}/acceptRoutes/object/${encodeURIComponent(id)}`
  )
  return response.data
}

export const patchGroup = async <T extends NamedIndividualStaticProps>(
  endpoint: string,
  _class: string,
  request: { ids: string[]; query: Query<NamedIndividualStaticProps> },
  language?: string
): Promise<Api.Response<SetResult<DynEntity<T>>>> => {
  let url = '/endpoints/' + endpoint + '/classes/' + newEncodeURIComponent(_class) + '/individuals'
  if (language) {
    url = url + '?language=' + language
  }
  const response = await apiClient.patch(url, request, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  })
  return response.data
}

export const deleteGroup = async (
  endpoint: string,
  _class: string,
  idList: string[],
  language?: string
): Promise<Api.Response<DropResult>> => {
  if (!idList.length) {
    throw new Error('empty request')
  }
  let url =
    '/endpoints/' + endpoint + '/classes/' + newEncodeURIComponent(_class) + '/individuals/delete'
  if (language) {
    url = url + '?language=' + language
  }
  const response = await apiClient.post(url, idList)
  return response.data
}

const rdfTypeParam = newEncodeURIComponent(RDF.type)

export const getTypeOnly = async (
  endpoint: string,
  id: string,
  language?: string
): Promise<Api.Response<PureEntity<{
  [RDF.type]: ReferenceValue[]
}>>> => {
  let url =
    '/endpoints/' +
    endpoint +
    '/individuals/' +
    newEncodeURIComponent(id) +
    '?props=' +
    rdfTypeParam
  if (language) {
    url = url + '&language=' + language
  }
  const response = await apiClient.get(url)
  return response.data
}
