import { Language } from '@/constants'
import type {
	SortDirection,
	FilterGroup,
	ReferenceValue,
	ObjectType,
	AllowedObject,
	Schema,
	NamedIndividual,
} from '~/shared'
import { InputType, ARCHIGRAPH, OWL, RDFS, XSD } from '~/shared'

import { equals, pick } from 'ramda'
import { ObjectCondition } from '@/core/filtering/types'
import { Entity } from '~/shared/new-types'

export {
	classSchema,
	referenceAttributeSchema,
	literalAttributeSchema,
	basicNamedIndividualSchema,
} from '~/shared'

export type TranslatableLabel = {
	[P in Language]: string
}

type CommonProps = {
	id: string
	[RDFS.label]: string
	[RDFS.comment]: string
	[ARCHIGRAPH.archive]: boolean
	[RDFS.isDefinedBy]: ReferenceValue[]
}

export const commonSchema: Schema<CommonProps> = {
	[RDFS.label]: {
		literal: XSD.string,
		minCar: 1,
		maxCar: 1,
	},
	[RDFS.comment]: {
		literal: XSD.string,
		minCar: 0,
		maxCar: 1,
		inputType: InputType.textArea,
	},
	[ARCHIGRAPH.archive]: {
		literal: XSD.boolean,
		minCar: 1,
		maxCar: 1,
	},
	[RDFS.isDefinedBy]: {
		reference: [{ type: OWL.NamedIndividual }],
		minCar: null,
		maxCar: null,
		multiple: true,
	},
}

export type OptionsQuery = {
	offset?: number
	limit?: number
	id?: string
	filter?: FilterGroup
	types: string[]
}

export type OptionsPackage = {
	options: NamedIndividual[]
	totalCount: number
}

export type OptionsResolver = (
	query: OptionsQuery,
	abortController?: AbortController
) => Promise<OptionsPackage>

export type Column<T extends AllowedObject = { id: string }, K extends keyof T = keyof T> = {
	key: K
	width?: number
}

export type Row<T> = {
	key: string
	selected: boolean
	checked: boolean
	data: T
}

export type Query<T extends AllowedObject = { id: string }> = {
	page: number
	pageSize?: number
	filter: ObjectCondition<Omit<T, 'id'>>
	columns: Column<T & { id: string }>[]
	showInherited: boolean
	sort: SortItem
	multiSort: SortItem[]
}

export const emptyQuery = <T extends AllowedObject = { id: string }>(): Query<T> => ({
	page: 1,
	columns: [{ key: 'id' }, { key: RDFS.label }],
	filter: null,
	showInherited: false,
	sort: null,
	multiSort: [],
})

export const getRef: (obj: ReferenceValue | Entity<any>) => ReferenceValue = pick(['id', 'name'])

export type SortItem = {
	key: string
	direction: SortDirection
}

export type Endpoint = {
	id: string
	name: string
}

export type UserCredentials = {
	username: string
	password: string
}

export type User = {
	name: string
}

export { ObjectType }

export type TimezoneOption = 'GMT' | 'local'

export type NavigationLink = {
	label: string | TranslatableLabel | I18nRef
	ref: string
}

export type I18nRef = {
	i18n: string
}

export const isI18nRef = (any: any): any is I18nRef =>
	typeof any === 'object' && equals(Object.keys(any), ['i18n']) && typeof any['i18n'] === 'string'
