import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {
  basicNamedIndividualSchema, NamedIndividual,
  OWL, RDF, RDFS,
  ReferenceValue, Schema, ShallowClass,
} from "~/shared";
import {emptyQuery, Query} from "@/types";
import QueryStorage from "@/core/query-storage";
import {get, getClassInstanceSchema, GetIndividualsResponse, getList, RequestFilteringParams} from "@/api/individuals";
import {apiClient} from "@/api/client";
import {ObjectCondition} from "@/core/filtering/types";
import {objectConditionToFilteringParams} from "@/core/filtering/convertor";
import {equals, pick} from 'ramda'

export const DEFAULT_PAGE_SIZE = 10

let queryStorage

const queryKey = (currentClass: ShallowClass) => `agmir_query_${currentClass.id}_${OWL.NamedIndividual}`
const optionalConditionToFilteringParams = (condition: ObjectCondition<any> | null): RequestFilteringParams => (condition ? objectConditionToFilteringParams(condition) : {})

class State {
  pending: boolean = false
  isModelStorage: boolean = false
  list: NamedIndividual[] = []
  totalCount: number = null
  schema: Schema<any> = basicNamedIndividualSchema
  query: Query<ReferenceValue & { [RDFS.label]: string }> = emptyQuery()
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  setQuery(state, payload) {
    state.query = payload
    if (!state.query.multiSort) {
      state.query.multiSort = []
    }
    queryStorage.set(queryKey(this.state.classes.current), state.query)
  },
  setPending(state, payload) {
    state.pending = payload
  },
  setSchema(state, payload) {
    state.schema = payload
  },
  setList(state, payload) {
    state.list = payload
  },
  setTotalCount(state, payload) {
    state.totalCount = payload
  },

  add(state, payload: NamedIndividual) {
    state.list.unshift(payload)
  },
  patch(state, payload: NamedIndividual) {
    if (payload === undefined) return

    const index = state.list.findIndex((item) => item.id === payload.id)

    if (index === -1) return;
    const object = state.list[index]
    if (payload.props[RDF.type].findIndex((item) => item.id === this.state.classes.current?.id) < 0) {
      state.list.splice(index, 1)
    }
    else {
      Object.assign(object.props, payload.props)
      if (object.name !== payload.name) {
        object.name = payload.name
      }
    }
  },
  remove(state, payload: string) {
    const index = state.list.findIndex(item => item.id === payload)
    state.list.splice(index, 1)
  },
  removeGroup(state, payload: string[]) {
    state.list = state.list.filter(el => !payload.includes(el.id))
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    const endpoint = context.rootState.endpoints.endpoint
    const user = context.rootState.auth.user
    const currentClass = context.rootState.classes.current
    queryStorage = new QueryStorage(endpoint.id, user.login)

    context.commit('setList', [])
    context.commit('setTotalCount', 0)

    if (currentClass?.id) {
      const saved = queryStorage.get(queryKey(currentClass)) ?? emptyQuery()
      // console.log(saved)
      context.commit('setQuery', saved)
      // if (saved) context.commit('setQuery', saved)
      // else context.commit('setQuery', emptyQuery())
      context.dispatch('loadIndividuals')
    }
  },

  async getIsModelStorage(classId: string) {
    try {
      if (classId) {
        const {data} = await get(this.$store.state.endpoints.endpoint.id, classId, null, OWL.Class, this.$store.state.ontologyVersions.isOn ?? false)
        this.isModelStorage = data.meta.isModelStorage
      }
    } catch (e) {
      this.handleError(e)
    }
  },

  async loadIndividuals(context) {
    const endpoint = context.rootState.endpoints.endpoint
    const currentClass = context.rootState.classes.current
    const lang = context.rootState.lang.currentLang
    let query = context.state.query
    const pageSize = query.pageSize ?? DEFAULT_PAGE_SIZE

    if (!currentClass?.id) return

    context.commit('setPending', true)

    let abortController = new AbortController();
    try {
      const schemaResponse = await getClassInstanceSchema(
        endpoint.id,
        currentClass?.id,
        lang.id,
        abortController,
        context.rootState.ontologyVersions.viewVersion
      )
      context.commit('setSchema', schemaResponse.data)

      let response: GetIndividualsResponse
      const requestList = () => {
        return getList(
          endpoint.id,
          currentClass?.id,
          {
            limit: pageSize,
            offset: (query.page - 1) * pageSize,
            withoutSubClasses: !query.showInherited,
            sort: query.multiSort,
            ...optionalConditionToFilteringParams(query.filter),
            columns: query.columns,
          },
          lang.id,
          abortController,
          context.rootState.ontologyVersions.viewVersion
        )
      }
      try {
        response = await requestList()
      } catch (e) {
        this.handleError(e)
        context.commit('setList', [])
        context.commit('setTotalCount', 0)
      }

      if (query.page > 1 && response.items.length === 0) {
        query = {...query, page: 1}
        context.commit('setQuery', query)
        try {
          response = await requestList()
        } catch (e) {
          this.handleError(e)
        }
      }

      if (response) {
        const list = response.items
        context.commit('setTotalCount', +response.totalCount)
        const {data} = await apiClient.get(`acceptRoutes/byClass/${endpoint.id}/${encodeURIComponent(currentClass?.id)}`)
        list.push(...data)
        context.commit('setList', list)
      }
    } catch (e) {
      this.handleError(e)
    } finally {
      context.commit('setPending', false)
    }
  },

  async setQuery(context, payload) {
    const significantProps = pick([
      'filter',
      'page',
      'pageSize',
      'sort',
      'showInherited',
      'multiSort',
      'columns',
    ])
    const needReload = !equals(significantProps(payload), significantProps(context.state.query))
    context.commit('setQuery', payload)
    if (needReload) {
      await context.dispatch('loadIndividuals')
      // if (this.individualsQuery.page > 1 && !this.individualsList?.length) {
      //   this.individualsQuery = {...this.individualsQuery, page: 1}
      //   this.queryStorage.set(this.queryKey, this.individualsQuery)
      //   this.loadIndividuals()
      // }
    }
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
