import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {
  NamedIndividual
} from "~/shared";
import {list as listAttributes} from "@/api/attributes";
import {list as listReferences} from "@/api/references";

class State {
  pending: boolean = false
  list: NamedIndividual[] = []
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {

  setPending(state, payload) {
    state.pending = payload
  },
  setList(state, payload) {
    state.list = payload
  },

  add(state, payload) {
    state.list.push(payload)
  },
  patch(state, payload) {
    const currentAttribute = state.list.find((el) => el.id === payload.id)
    if (!currentAttribute) return

    Object.assign(currentAttribute.props, payload.props)
    currentAttribute.name = payload.name
  },
  remove(state, payload) {
    const attributeIndex = state.list.findIndex((el) => payload === el.id)
    if (attributeIndex > -1) {
      state.list.splice(attributeIndex, 1)
    }
  },
}

const actions: ActionTree<State, any> = {
  async init(context) {

  },

  async load(context) {
    context.commit('setList', [])

    const lang = context.rootState.lang.currentLang
    const endpoint = context.rootState.endpoints.endpoint
    const cls = context.rootState.classes.current
    const viewVersion = context.rootState.ontologyVersions.viewVersion

    const list = await listReferences(endpoint.id, cls.id, lang.id, viewVersion)

    context.commit('setList', list.data)
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
