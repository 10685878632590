import { RequestFilteringParams } from '@/api/individuals'
import { AllowedObject, AllowedValue, Comparison, FilterGroup, ReferenceValue } from '~/shared'
import { Condition, ObjectCondition } from '@/core/filtering/types'
import { comparators } from '@/core/filtering/comparators'
import { prop } from 'ramda'
import format from 'date-fns/esm/fp/format'
import { parseInterval } from '@/utils/date-interval'

type ConditionToFilterGroup<
	TObject extends AllowedObject,
	K extends keyof TObject,
	ComparatorName extends keyof typeof comparators
> = (condition: Condition<TObject, K, ComparatorName>) => FilterGroup

type SimpleConverterParams<TSample> = {
	comparison: Comparison
	convert?: (sample: TSample) => string
	byName?: boolean
	uom?: {
		id: string
	}
}

const simpleConverter =
	<TSample extends AllowedValue | AllowedValue[]>(
		params: SimpleConverterParams<TSample>
	): ConditionToFilterGroup<any, any, any> =>
	(condition) => {
		return {
			operation: 'AND',
			filters: [
				{
					key: condition.field,
					comparison: params.comparison,
					value:
						typeof params.convert === 'function'
							? params.convert(condition.sample as TSample)
							: condition.sample,
					byName: params.byName,
					uom: condition.uom?.id,
				},
			],
		}
	}

const formatDate = format('yyyy-MM-dd')
const formatDateTime = format("yyyy-MM-dd'T'HH:mm:ss")

export const conversions: {
	[P in keyof typeof comparators]: ConditionToFilterGroup<any, any, any>
} = {
	stringEquals: simpleConverter({
		comparison: Comparison.Equal,
	}),
	stringNotEquals: simpleConverter({
		comparison: Comparison.NotEqual,
	}),
	stringContains: simpleConverter({
		comparison: Comparison.Contains,
	}),
	stringBeginningContains: simpleConverter({
		comparison: Comparison.BeginningContains,
	}),
	stringEqualsStrict: simpleConverter({
		comparison: Comparison.iEqual,
	}),
	stringRegEx: simpleConverter({
		comparison: Comparison.RegEx,
	}),
	numberEquals: simpleConverter({
		comparison: Comparison.Equal,
	}),
	numberNotEquals: simpleConverter({
		comparison: Comparison.NotEqual,
	}),
	numberMoreThan: simpleConverter({
		comparison: Comparison.More,
	}),
	numberMoreOrEqualsThan: simpleConverter({
		comparison: Comparison.MoreOrEqual,
	}),
	numberLessThan: simpleConverter({
		comparison: Comparison.Less,
	}),
	numberLessOrEqualsThan: simpleConverter({
		comparison: Comparison.LessOrEqual,
	}),
	refEquals: simpleConverter<ReferenceValue>({
		comparison: Comparison.Equal,
		convert: prop('id'),
	}),
	refNotEquals: simpleConverter<ReferenceValue>({
		comparison: Comparison.NotEqual,
		convert: prop('id'),
	}),
	refArrayContains: simpleConverter<ReferenceValue>({
		comparison: Comparison.Equal,
		convert: prop('id'),
	}),
	refArrayNotContains: simpleConverter<ReferenceValue>({
		comparison: Comparison.NotEqual,
		convert: prop('id'),
	}),
	booleanEquals: simpleConverter({
		comparison: Comparison.Equal,
		convert: String,
	}),
	booleanNotEquals: simpleConverter({
		comparison: Comparison.NotEqual,
		convert: String,
	}),
	referenceNameContains: simpleConverter({
		comparison: Comparison.Contains,
		byName: true,
	}),
	anyExists: simpleConverter({
		comparison: Comparison.Exists,
		convert: String,
	}),
	anyNotExists: simpleConverter({
		comparison: Comparison.NotExists,
		convert: String,
	}),
	dateWithinTimespan: <K extends string>({
		field,
		sample,
	}: Condition<{ [P in K]: string }, K, 'dateWithinTimespan'>) => {
		const { start, end } = parseInterval(sample)
		return {
			filters: [
				{
					key: field,
					comparison: Comparison.MoreOrEqual,
					value: formatDate(start),
				},
				{
					key: field,
					comparison: Comparison.LessOrEqual,
					value: formatDate(end),
				},
			],
			operation: 'AND',
		}
	},
	dateTimeWithinTimespan: <K extends string>({
		field,
		sample,
	}: Condition<{ [P in K]: string }, K, 'dateTimeWithinTimespan'>) => {
		const { start, end } = parseInterval(sample)
		return {
			filters: [
				{
					key: field,
					comparison: Comparison.MoreOrEqual,
					value: formatDateTime(start),
				},
				{
					key: field,
					comparison: Comparison.LessOrEqual,
					value: formatDateTime(end),
				},
			],
			operation: 'AND',
		}
	},
	subrequest({ field, sample }: any) {
		if (!sample) {
			return { filters: [] }
		}
		sample = structuredClone(sample)
		sample.filter = sample.filter.conditions.map(conditionToFilterGroup)
		return {
			filters: [{ key: field, comparison: Comparison.Equal, subrequest: sample }],
			operation: 'AND',
		}
	},
}

export const conditionToFilterGroup = (condition: Condition<any, any, any>): FilterGroup => {
	const conversion = conversions[condition.comparator]
	return conversion(condition)
}

export const objectConditionToFilteringParams = <T extends AllowedObject>({
	operation,
	conditions,
}: ObjectCondition<T>): RequestFilteringParams => {
	return {
		operation,
		filter: conditions.map(conditionToFilterGroup),
	}
}
