import { AppDiscovery } from './dicovery'

export enum Comparison {
	Equal = 'Equal',
	Contains = 'Contains',
	BeginningContains = 'BeginningContains',
	More = 'More', // (больше)
	Less = 'Less', // (меньше)
	MoreOrEqual = 'MoreOrEqual', // (больше или равно)
	LessOrEqual = 'LessOrEqual', // (меньше или равно)
	NotEqual = 'NotEqual', // (не равно)
	Exists = 'Exists', // (значение задано)
	NotExists = 'NotExists', // (значение атрибута не задано)
	iEqual = 'iEqual', // (равно с точностью до регистра)
	RegEx = 'regex', // регулярка
}
import { OWL } from './constants'
export type { ImportResult, ImportResultItem, ImportResultList } from './exchange'
export { ImportType } from './exchange'

export type SortDirection = 'ASC' | 'DESC'

export type Operation = 'AND' | 'OR'

export type BaseFilterItem<K, C extends Comparison, V> = {
	key: K
	comparison: C
	value: V
	byName?: boolean
	uom?: string
	subrequest?: { class: string; filter: FilterGroup[] }
}

export type FilterGroup = {
	operation: Operation
	filters: BaseFilterItem<any, Comparison, any>[]
}

export type BaseSortItem<K, D extends SortDirection> = {
	key: K
	direction: D
}

export type AttributeType = OWL.DatatypeProperty | OWL.ObjectProperty

export type InputReferenceValue = {
	id: string
	name?: string
}

export type ReferenceValue = {
	id: string
	name: string
}

export type TypedReferenceValue<T> = ReferenceValue & {
	type: T
}

export type TranslatableReferenceValue = {
	id: string
	name: Record<string, string>
}

export const isReferenceValue = (any: any): any is ReferenceValue =>
	typeof any.id === 'string' && typeof any.name === 'string'

export type UserInfo = {
	name: string
	originator: { originator: string }
}

export type Language = {
	id: string
	name: string
	isDefault: boolean
}

export type CommitData = {
	refName: string
	shortSha: string
	tag: string
}

export type AuthMethod = 'sso' | 'credentials'

export const isAuthMethod = (any: any): any is AuthMethod => any === 'sso' || any === 'credentials'

export type AuthConfig = {
	method: AuthMethod
	oauthLoginUrl: string
}

export type Timezone = {
	name: string
	offset: string
}

export type AppConfig = {
	discovery: AppDiscovery
	timezones: Timezone[]
	auth: AuthConfig
	defaultUiLanguage: string
	mainEditor?: string
	SUZ_FRONT_URL?: string
	IS_SIBUR?: boolean
}

export type TranslatableLabel = Record<string, string>

export type SetResultItem<T> = {
	result: T | null
	error: Error | null
}

export type SetResult<T> = SetResultItem<T>[]

export type DropResultItem = ReferenceValue & {
	error?: Error
}

export type DropResult = DropResultItem[]

export type Scope = {
	originator: string
	endpoint: string
}

export type MayBeArray<T> = T | T[]

export type UserSettings = {
	timezone: string // the timezone which dates will be distaplayed
	useLDM: boolean // add System='НСИ' to GetObject/GetObjectsGroup requests
	useLogic: Record<string, boolean> // add Logic="1" to ChangesRequests
	useCheck: Record<string, boolean> // add Check="1" to ChangesRequests
	hideCode: boolean
	temporality: string
	useTemporalityForSingle?: boolean
	useTemporalityForGroup?: boolean
}
